























import { BaseComponent, Component } from '@/components/BaseComponent';

@Component
export default class AutoLogin extends BaseComponent {
  identifiers: string[] = [];

  get loginPage() {
    return { name: this.Pages.Login };
  }

  get settings() {
    return this.store.getSettings();
  }

  created() {
    const page = this.store.getPage(this.Pages.AutoLogin);
    if (page) {
      this.identifiers = page.autologin_identifiers;
    } else {
      this.router.goTo(this.Pages.Login);
    }
  }

  async autoLogin(identifier: string) {
    const result = await this.pds.autoLogin({ identifier });
    this.router.changePage(result);
  }

  async logout(index: number) {
    const identifier = this.identifiers[index];
    const result = await this.pds.logout({ identifier });
    if (result.isFailure()) {
      this.router.changePage(result);
    } else if (result.value.page_to_show === this.Pages.AutoLogin) {
      this.identifiers = result.value.payload.autologin_identifiers;
    } else {
      this.router.changePage(result);
    }
  }
}
